import request from '../request'

//请求列表
export const getPartyMemberInfor = (params) => {
    return request({
        url: `/blade-party-admin/person/info/pageNoDesensitized`,
        params: params,
        method: 'GET',
        headers: {
            'Blade-Auth': 'bearer eyJ0eXAiOiJKc29uV2ViVG9rZW4iLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJpc3N1c2VyIiwiYXVkIjoiYXVkaWVuY2UiLCJ0ZW5hbnRfaWQiOiI5Mzg5NDIiLCJyb2xlX25hbWUiOiIiLCJ1c2VyX2lkIjoiMTI0ODE3MzAzMjUyMjkxNTg0MiIsInVzZXJfbmFtZSI6IjE3NzMwMjcyOTYyIiwibmlja19uYW1lIjoi6ZmI6YeR5a6dIiwidG9rZW5fdHlwZSI6ImFjY2Vzc190b2tlbiIsImRlcHRfaWQiOiIxMjQxNjI1OTU4ODY0MTYyODE4IiwiYWNjb3VudCI6IjE3NzMwMjcyOTYyIiwiY2xpZW50X2lkIjoic3dvcmQiLCJleHAiOjE1OTQ2MzQwNzgsIm5iZiI6MTU4NzQzNDA3OH0.5awy-liXOBX3HQqH_IvCrJ61TNp1K-sMKKDjAuUETdo',
        }
    })
};