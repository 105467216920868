<!--党组织架构-->
<template>
  <div class="content">
    <!-- 第一块 -->
    <div class="line">
      <div class="lineBox">
        <img v-if="firstInfo.avatar" :src="firstInfo.avatar" />
        <img v-else src="../memberInfo/imgs/avatar.png" />
        <div class="name">
          {{ firstInfo.realName }}
        </div>
        <div class="position">
          {{ firstInfo.positionName }}
        </div>
      </div>
    </div>
    <!-- 第二块 -->
    <div class="line">
      <div class="lineBox" v-for="(item, index) of infoList1" :key="index">
        <img v-if="item.avatar" :src="item.avatar" />
        <img v-else src="../memberInfo/imgs/avatar.png" />
        <div class="name">
          {{ item.realName }}
        </div>
        <div class="position">
          {{ item.positionName }}
        </div>
      </div>
    </div>
    <!-- 第三块 -->
    <div class="line">
      <div class="lineBox" v-for="(item, index) of infoList2" :key="index">
        <img v-if="item.avatar" :src="item.avatar" />
        <img v-else src="../memberInfo/imgs/avatar.png" />
        <div class="name">
          {{ item.realName }}
        </div>
        <div class="position">
          {{ item.positionName }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getDictionary } from '@/api/organization';
import { getPartyMemberInfor } from '@/api/partyMemberInfor';
export default {
  name: 'organization',
  data() {
    return {
      current: 0,
      firstInfo: {},
      infoList1: [],
      infoList2: [],
    };
  },
  created() {
    this.getInfoList();
  },
  methods: {
    //获取列表数据
    getInfoList() {
       getDictionary({
         current: 1,
         size: 10,
         code: "POSITION"
       }).then(res => {
         if(res.success){
           const data =  res.data
           console.log("获取职务成功",data)
           data.forEach( (item,index) =>{
             if(item.dictValue.indexOf("书记") != -1 && item.dictValue.indexOf("副") == -1){
              getPartyMemberInfor({
                current: 1,
                size: 1,
                positionKey:item.dictKey
              }).then(res => {
                if(res.success && res.data.records.length > 0){
                  this.firstInfo = res.data.records[0]
                  console.log("书记信息:",this.firstInfo)
                }
              }).catch(err => {
                console.log(err)
              })
             }else if(item.dictValue.indexOf("副书记") != -1){
                getPartyMemberInfor({
                  current: 1,
                  size: 3,
                  positionKey:item.dictKey
                }).then(res => {
                  if(res.success && res.data.records.length > 0){
                    res.data.records.forEach((item,index)=>{
                      this.infoList1.push(item)
                    })
                    console.log("副书记信息",this.infoList1)
                  }
                }).catch(err => {
                  console.log(err)
                })
             }else if(item.dictValue.indexOf("党员") == -1){
                getPartyMemberInfor({
                  current: 1,
                  size: 10,
                  positionKey:item.dictKey
                }).then(res => {
                  if(res.success && res.data.records.length > 0){
                    res.data.records.forEach((item,index)=>{
                      this.infoList2.push(item)
                    })
                    console.log("非党员信息:",this.infoList2)
                  }
                }).catch(err => {
                  console.log(err)
                })
             }
           })

         }
       }).catch(err => {
        console.log(err)
       })
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
  min-height: 100%;
  flex-wrap: wrap;
  padding-top: 26px;
  background: #ffe7c4 url(./imgs/bg.png) no-repeat scroll top left;
  .line {
    // width: 100%;
    display: flex;
    align-items: column;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .lineBox {
      width: 110px;
      color: #333333;
      text-align: center;
      margin-bottom: 40px;
      position: relative;
      > img {
        width: 110px;
        height: 124px;
      }
      .name {
        width: 100%;
        height: 28px;
        line-height: 28px;
        background-color: #e4c678;
        font-size: 17px;
        font-weight: 500;
      }
      .position {
        font-size: 14px;
      }
    }
  }
  .line:nth-child(3) {
    padding: 0 16px;
    justify-content: space-between;
  }
}
</style>
